import React from 'react'
import IconLinkedIn from '../../images/ln.svg'
import IconTwitter from '../../images/tw.svg'
import LogoPremier from '../../images/premier-logo.svg'
import { Link } from 'gatsby'

function Footer(props) {

    const {data} = props

    return (
        <div className="footer">
            <div className="footer-row">
                <div className="logo-premier">
                    <Link target="_blank" rel="noreferrer" to={"https://premierinc.com"} className="logo-premier">
                        <LogoPremier />
                    </Link>
                </div>
                <div className="footer-social">
                     <Link target="_blank" rel="noreferrer" to={"https://twitter.com/PremierHA"}>
                        <IconTwitter />
                     </Link>
                     <Link target="_blank" rel="noreferrer" to={"https://linkedin.com/company/6766"}>
                        <IconLinkedIn />
                     </Link>
                </div>
            </div>

            <div className="footer-row">
                <div className="footer-info-item">
                    {data.footerAddressStreet}<br />
                    {data.footerAddressCityStateZip}
                </div>
            </div>

            <div className="footer-row">
                <div className="footer-info">
                    <div className="footer-info-item">
                        <strong>Phone: </strong>
                        <a href={`tel:+1${data.footerPhone.replace(/\D/g, "")}`}
                        rel="noreferrer" target="_blank">
                            {data.footerPhone}
                        </a>
                    </div>
                    <div className="footer-info-item">
                        <strong>Solution Center: </strong>
                        <a href={`tel:+1${data.footerSolutionCenter.replace(/\D/g, "")}`}
                        rel="noreferrer" target="_blank">
                            {data.footerSolutionCenter}
                        </a>
                    </div>
                    <div className="footer-info-item">
                        <a className="privacy-footer-link" target="_blank" rel="noreferrer" href="https://www.premierinc.com/privacy-notice">Privacy Policy</a>

                        <a href='/' rel="noreferrer" className="optanon-show-settings">Cookie Settings</a>
                    </div>
                </div>

                <div className="footer-copyright">
                    {data.footerCopyright}
                </div>
            </div>
        </div>
    )
}

export default Footer
