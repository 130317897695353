import React from 'react'
import PDFIcon from '../../images/pdf-file-icon.svg'
import PPTIcon from '../../images/ppt-file-icon.svg'
import JPGIcon from '../../images/jpg-file-icon.svg'
import BASEIcon from '../../images/base-file-icon.svg'

import DownloadArrowIcon from '../../images/download-arrow-icon.svg'



function VideoDownload(props) {
    const { item } = props
    const {title,description,_type} = item

    const type = _type === "videoDownload" ? item.type : "img"

    function iconGen(type) {
        switch(type) {
            case "pdf":
                return (
                    <PDFIcon />
                )
            case "ppt":
                return (
                    <PPTIcon />
                )
            case "img":
                return (
                    <JPGIcon />
                )
            default:
                return (
                    <BASEIcon />
                )
        }
    }

    return (
        <a href={item.asset.url} className="video-download" target="_blank" rel="noopener" download>
            <div className="video-download-icon">{iconGen(type)}</div>
            <div className="video-download-text">
                <div className="video-download-title">{title}</div>
                <div className="video-download-description">{description}</div>
            </div>
            <div className="video-download-button">
                <DownloadArrowIcon />
            </div>
        </a>
    )
}

export default VideoDownload