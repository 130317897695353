import React from 'react'

function SpeakerItem(props) {

    const { bio } = props

    return (
        <div className="speaker">
            <div className="speaker-img">
                <img src={bio.headShotImage.asset.url} alt="" />
            </div>
            <div className="speaker-content">
                <div className="speaker-content-top">
                    <div className="speaker-img-mobile">
                        <img src={bio.headShotImage.asset.url} alt="" />
                    </div>
                    <div className="speaker-content-top-text">
                        <div className="speaker-name">
                            {`${bio.firstName} ${bio.lastName}`}
                        </div>
                        <div className="speaker-title">
                            {bio.professionalTitle}
                        </div>
                    </div>
                </div>
                <div className="speaker-info" dangerouslySetInnerHTML={{__html: bio.info}}/>
            </div>
        </div>
    )
}

export default SpeakerItem
