import React from 'react'

function AgendaItem(props) {

    const {item} = props

    const itemSpeakers = item?.speakers ? item.speakers.map((speaker,speakerIndex) => {
        return (
            <div key={`speaker-item-${speakerIndex}`} className="agenda-item-speaker">
                {`${speaker.firstName} ${speaker.lastName}`}
                <span>{`${speaker.professionalTitle}`}</span>
            </div>
        )
    }) : ""

    const titleGen = title => {
        console.log('title', title)
        return (
            <div className="agenda-item-title"><span dangerouslySetInnerHTML={{__html: title}}></span></div>
        )
    }
    const topicGen = topic => <div className="agenda-item-topic"><strong>Topic: </strong>{topic}</div>

    const getTitle = item?.title ? titleGen(item.title) : ''
    const getTopic = item?.topic ? topicGen(item.topic) : ''

    const speakerLineClass = itemSpeakers.length > 0 ? "agenda-item-speakers-line" : ""

    return (
        <div className="agenda-item">
            <div className="agenda-item-timestamp">{item?.time}</div>
            <div className="agenda-item-content">
                {getTitle}
                {getTopic}
                <div className={`agenda-item-speakers ${speakerLineClass}`}>
                    {itemSpeakers}
                </div>
            </div>
        </div>
    )
}

export default AgendaItem
