// extracted by mini-css-extract-plugin
export var heroContainer = "Hero-module--heroContainer--3jISH";
export var heroSubText = "Hero-module--heroSubText--1SG7H";
export var heroSection = "Hero-module--hero-section--Qz1vo";
export var fogIn = "Hero-module--fogIn--2Q6eP";
export var heroBox = "Hero-module--heroBox--cJWcn";
export var heroBoxFg = "Hero-module--heroBoxFg--1tQis";
export var heroSectionOutlined = "Hero-module--heroSectionOutlined--2Ps8n";
export var heroBoxFgText = "Hero-module--heroBoxFgText--2vpdU";
export var heroText = "Hero-module--hero-text--2bAZ4";
export var svgPath = "Hero-module--svgPath--39zLG";
export var heroDate = "Hero-module--heroDate--1CX_X";
export var mobileLogo = "Hero-module--mobileLogo--icGg-";
export var textIn1 = "Hero-module--textIn1--hQiUI";