import React, { Component } from 'react'

class VideoEmbed extends Component {

    componentDidMount() {
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://player.manage.broadcastcloud.tv/js/embed-player.js');

        document.body.appendChild(script);
    }

    render() {
        return (
            <div className="video-wrapper">
                <div
                    data-ikonik-player-type="default"
                    data-ikonik-player-id="2248_rgHMofiBn9qxuk12AIB3AUgJGlkQHcmQIV8kC2nBpM8"
                    data-ikonik-player-autoplay="false" data-ikonik-player-debug="false"></div>
            </div>
        );
    }
}

export default VideoEmbed