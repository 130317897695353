// extracted by mini-css-extract-plugin
export var modalOverlay = "SignUpModal-module--modalOverlay--20VOs";
export var modalOverlayClosed = "SignUpModal-module--modalOverlayClosed--2P3fO";
export var modalBox = "SignUpModal-module--modalBox--3-3b0";
export var modalContent = "SignUpModal-module--modalContent--2EGgx";
export var formControl = "SignUpModal-module--formControl--1lHMx";
export var controlContainer = "SignUpModal-module--controlContainer--2DvN9";
export var error = "SignUpModal-module--error--1eobW";
export var modalButton = "SignUpModal-module--modalButton--2Oh5S";
export var premierLogoContainer = "SignUpModal-module--premierLogoContainer--3BzAW";
export var investorDayBannerContainer = "SignUpModal-module--investorDayBannerContainer--1bfou";
export var formContainer = "SignUpModal-module--formContainer--1_nli";