import React, { useState, useEffect, useRef } from 'react'
import Hls from 'hls.js'
import last from 'lodash/last'
import placeholder from '../../images/video-placeholder.png'

function setupVideoPlayer(videoPlayer,vsrc) {
    if (videoPlayer.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoPlayer.current.src = vsrc
        
        // If no native HLS support, check if HLS.js is supported
    } else if (Hls.isSupported()) {
        const hls = window.PREMIERHLS || new Hls()

        window.PREMIERHLS = hls

        hls.loadSource(vsrc)
        hls.attachMedia(videoPlayer.current)
        hls.on(Hls.Events.ERROR, (event, data) => {
            console.log('HLS.Events.ERROR: ', event, data);
            const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        hls.startLoad()
                        break
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            hls.recoverMediaError()
                            break
                            default:
                                // cannot recover
                                hls.destroy()
                                break
                            }
            } else if (data.details === 'internalException' && data.type === 'otherError' && isMobile) {
                const level = last(this.getLevels())
            
                hls.removeLevel(level?.level, level?.urlId)
            
                hls.startLoad()
            }
        })
    }
}

function VideoGallery(props) {
    const testsrc = "https://vod01.broadcastcloud.tv/13/8350/8350_a8c06c133ae7a424d73096dfcae257bf-adaptive/playlist.m3u8"

    const { toggled, handleClick, sessions } = props

    const [vsrc, setVsrc] = useState(sessions[0].video)

    const videoPlayer = useRef(null)

    const sessionList = sessions.map((session,index) => {
        return (
            <div key={`session-${index}`} className={`video-gallery__session ${vsrc === session.video ? 'video-gallery__session-selected' : ''}`} 
                onClick={()=>setVsrc(session.video)}>
                <img src={session.image} alt={`video-img-${index}`}/>
            </div>
        )
    })

    useEffect(() => {
        console.log("USEEFFECT")
        if (toggled) setupVideoPlayer(videoPlayer,vsrc)
    })

    return (
        <div className={`video-gallery ${toggled ? 'video-gallery-show' : ''}`}>
            <div className="video-gallery__video-wrapper">
                <video className="video-gallery__video" ref={ videoPlayer } 
                    placeholder={placeholder}
                    controls></video>
            </div>
            
            <div className="video-gallery__list-wrapper">
                <div className="video-gallery-chapters-title">CHAPTERS</div>
                <div className="video-gallery__list">
                    { sessionList }
                </div>
            </div>
        </div>
    )
}

export default VideoGallery