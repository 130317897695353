import React, { useState } from 'react'
import LiveChat from 'react-livechat'
import LiveChatLogo from '../../images/livechat-bubble-logo.svg'
import * as styles from './Chat.module.scss'

function Chat(props) {

    const [toggled, setToggled] = useState(false)
    const [chat, setChat] = useState(null)

    const handleClick = () => {
        setToggled(true)
        window.LC_API.open_chat_window()
    }

    const buttonClass = toggled ? styles.chatButtonActive : styles.chatButton
    return (
        <div className={styles.chatWrapper}>
            <div className={buttonClass} tabIndex={0} 
            onClick={handleClick} onKeyDown={handleClick} 
            role="button">
                <LiveChatLogo />
                <span>Ask a Question!</span>
            </div>
           <LiveChat
                license={12003771}
                onChatLoaded = {(ref) => {
                    ref.on_after_load = function ()  {
                        //here you can use any method you want!
                        if (chat) console.log('chat is loaded')
                        setChat(ref)
                    }
                }}

                onChatWindowMinimized={ () => setToggled(false) }
                onChatWindowHidden={ () => setToggled(false) }

                onMessage={ data => {
                    console.log('onMessage', data)
                }}
            />
            <div className={styles.chatSubtext}>
                *Ask a question during the live stream, and the speaker might answer live.
            </div>
        </div>
    )
}


export default Chat