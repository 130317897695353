import React from 'react'
import SpeakerItem from './SpeakerItem'

function Speakers(props) {

    const {title,bios} = props


    const speakerGen = bios.map((bio,bioIndex) => {
        return (
            <SpeakerItem key={`bio-${bioIndex}`} bio={bio} />
        )
    })

    return (
        <div className="speakers">
            <div className="speakers-title">{title}</div>
            <div className="speakers-list">
                {speakerGen}
            </div>
        </div>
    )
}

export default Speakers