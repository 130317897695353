import React, {useEffect, useRef} from 'react'



function Background() {

    const canvas = useRef()
    
    useEffect(()=>{
        //console.log(canvas.current)
        const ctx = canvas.current.getContext('2d')

        function Pixel(x, y, hue, saturation, lightness) {
            this.x = x
            this.y = y
            //this.hue = Math.floor(Math.random() * 360)
            this.hue = hue
            this.saturation = saturation
            this.lightness = lightness

            const direction = Math.random() > 0.5 ? -1 : 1
            this.velocity = (Math.random() * 30 + 20) * 0.01 * direction
        }
    
        Pixel.prototype.update = function () {
            this.hue += this.velocity
        }
    
        Pixel.prototype.render = function (ctx) {
            const hue = Math.round(this.hue)
            ctx.fillStyle = `hsl(${hue}, ${this.saturation}%, ${this.lightness}%)`
            ctx.fillRect(this.x, this.y, 1, 1)
        }
    
        let pixels = [
            new Pixel(0, 0, 0, 0, 0),
            new Pixel(1, 0, 199, 38, 28),
            new Pixel(0, 1, 16, 70, 49),
            new Pixel(1, 1, 202, 48, 26),
        ]
    
        function animate() {
            pixels.forEach(function (pixel) {
                pixel.update()
                pixel.render(ctx)
            })
    
            requestAnimationFrame(animate)
        }
    
        animate()
    }, [])

    return (
        <div className="background-wrapper">
            <canvas id="canvas" width="2" height="2" ref={canvas}></canvas>
        </div>
    )
}

export default Background