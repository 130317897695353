import React, { useEffect } from 'react'
import * as THREE from 'three'
import { rgb, randomInteger } from '../../utils/animationHelpers'

// import FOG from 'vanta/dist/vanta.fog.min'
import * as styles from './Hero.module.scss'

function Hero(props) {

    const {subText} = props

    const fogRef = React.createRef()
    const fogRef2 = React.createRef()
    const heroSection = React.createRef()

    const isBrowser = typeof window !== "undefined"

    useEffect(() => {
        const renderer1 = new THREE.WebGLRenderer()
        const renderer2 = new THREE.WebGLRenderer()

        // Animate on page load?
        // setTimeout(() => {
        //     heroSection.current.classList.add(styles.heroSectionOutlined)
        // }, 1800)

        // Using FOG from Vanta
        // const fogEffect = FOG({
        //     el: fogRef.current,
        //     THREE,
        //     blurFactor:1,
        //     highlightColor:0xD55325,
        //     midtoneColor:0xF7D0C0,
        //     lowlightColor:0xF4B848,
        //     baseColor:0x1D4964,
        // })
        //
        // return function cleanup() {
        //     fogEffect.destroy()
        // }

        // Custom THREE implementation
        const fogNode = fogRef.current
        const fogNode2 = fogRef2.current

        renderer1.setSize(1000, 400)
        renderer2.setSize(1000, 400)

        const rendererEl = renderer1.domElement
        const rendererEl2 = renderer2.domElement

        fogNode.appendChild(rendererEl)
        fogNode2.appendChild(rendererEl2)

        if(isBrowser) {
            const fogAnimation = (renderer) => {
                const scene = new THREE.Scene();
                const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );

                let vCheck = false;

                camera.position.z = 5;

                let randomisePosition = new THREE.Vector2(1, 2);

                let R = function(x, y, t) {
                    return( Math.floor(192 + 64*Math.cos( (x*x-y*y)/300 + t )) );
                }

                let G = function(x, y, t) {
                    return( Math.floor(192 + 64*Math.sin( (x*x*Math.cos(t/4)+y*y*Math.sin(t/3))/300 ) ) );
                }

                let B = function(x, y, t) {
                    return( Math.floor(192 + 64*Math.sin( 5*Math.sin(t/9) + ((x-100)*(x-100)+(y-100)*(y-100))/1100) ));
                }

                let sNoise = `
            vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
            vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
            vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }
    
            float snoise(vec2 v) {
                const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                                    0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                                    -0.577350269189626,  // -1.0 + 2.0 * C.x
                                    0.024390243902439); // 1.0 / 41.0
                vec2 i  = floor(v + dot(v, C.yy) );
                vec2 x0 = v -   i + dot(i, C.xx);
                vec2 i1;
                i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
                vec4 x12 = x0.xyxy + C.xxzz;
                x12.xy -= i1;
                i = mod289(i); // Avoid truncation effects in permutation
                vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
                    + i.x + vec3(0.0, i1.x, 1.0 ));
    
                vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
                m = m*m ;
                m = m*m ;
                vec3 x = 2.0 * fract(p * C.www) - 1.0;
                vec3 h = abs(x) - 0.5;
                vec3 ox = floor(x + 0.5);
                vec3 a0 = x - ox;
                m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
                vec3 g;
                g.x  = a0.x  * x0.x  + h.x  * x0.y;
                g.yz = a0.yz * x12.xz + h.yz * x12.yw;
                return 130.0 * dot(m, g);
            }
            `

                let geometry = new THREE.PlaneGeometry(window.innerWidth *.7, 400, 100, 100);

                //const yellow = rgb(244, 184, 72)
                const orange =  rgb(248, 83, 25)
                const orangeLight =  rgb(249, 144, 100)
                const blue = rgb(29, 73, 100)
                const pink = rgb(247, 208, 192)

                let material = new THREE.ShaderMaterial({
                    uniforms: {
                        u_bg: {type: 'v3', value: orangeLight},
                        u_bgMain: {type: 'v3', value: orange},
                        u_color1: {type: 'v3', value: pink},
                        u_color2: {type: 'v3', value: blue},
                        u_time: {type: 'f', value: 30},
                        u_randomisePosition: { type: 'v2', value: randomisePosition }
                    },
                    fragmentShader: sNoise + `vec3 rgb(float r, float g, float b) {
            return vec3(r / 255., g / 255., b / 255.);
        }

        vec3 rgb(float c) {
            return vec3(c / 255., c / 255., c / 255.);
        }

        uniform vec3 u_bg;
        uniform vec3 u_bgMain;
        uniform vec3 u_color1;
        uniform vec3 u_color2;
        uniform float u_time;

        varying vec2 vUv;
        varying float vDistortion;

        void main() {
            vec3 bg = rgb(u_bg.r, u_bg.g, u_bg.b);
            vec3 c1 = rgb(u_color1.r, u_color1.g, u_color1.b);
            vec3 c2 = rgb(u_color2.r, u_color2.g, u_color2.b);
            vec3 bgMain = rgb(u_bgMain.r, u_bgMain.g, u_bgMain.b);

            float noise1 = snoise(vUv + u_time * 0.1);
            float noise2 = snoise(vUv * 5. + u_time * 0.05);

            vec3 color = bg;
            color = mix(color, c1, noise1 * 0.8);
            color = mix(color, c2, noise2 * .4);

            color = mix(color, mix(c1, c2, vUv.x), vDistortion);

            float border = smoothstep(0.1, 0.6, vUv.x);

            color = mix(color, bgMain, 1. -border);

            gl_FragColor = vec4(color, 1.0);
        }`,
                    vertexShader: sNoise + `uniform float u_time;
        uniform vec2 u_randomisePosition;

        varying float vDistortion;
        varying float xDistortion;
        varying vec2 vUv;

        void main() {
            vUv = uv;
            vDistortion = snoise(vUv.xx * 3. - u_randomisePosition * 0.15);
            xDistortion = snoise(vUv.yy * 1. - u_randomisePosition * 0.05);
            vec3 pos = position;
            pos.z += (vDistortion * 35.);
            pos.x += (xDistortion * 25.);

            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }`
                });

                let mesh = new THREE.Mesh(geometry, material);
                mesh.position.set(-200, 270, -280);
                mesh.scale.multiplyScalar(3.5);
                mesh.rotationX = -1.0;
                mesh.rotationY = 0.0;
                mesh.rotationZ = 0.1;
                scene.add(mesh);

                renderer.render( scene, camera );
                let t = 0;
                let j = 0;
                let x = randomInteger(0, 32);
                let y = randomInteger(0, 32);
                const animate = function () {
                    requestAnimationFrame( animate );
                    renderer.render( scene, camera );
                    mesh.material.uniforms.u_randomisePosition.value = new THREE.Vector2(j, j);

                    mesh.material.uniforms.u_color1.value = new THREE.Vector3(R(x,y,t/2), G(x,y,t/2), B(x,y,t/2));

                    mesh.material.uniforms.u_time.value = t;
                    if(t % 0.1 === 0) {
                        if(vCheck === false) {
                            x -= 1;
                            if(x <= 0) {
                                vCheck = true;
                            }
                        } else {
                            x += 1;
                            if(x >= 32) {
                                vCheck = false;
                            }

                        }
                    }

                    // Increase t by a certain value every frame
                    j = j + 0.01;
                    t = t + 0.05;
                };
                animate();
            }

            fogAnimation(renderer1)
            fogAnimation(renderer2)
        }

        return function cleanup() {
            rendererEl.remove()
            rendererEl2.remove()
        }
    });

    return (

        <div className={styles.heroContainer}>
            <div className={styles.mobileLogo}>
                <svg width="300" height="52" viewBox="0 0 300 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.8182 30.7309H38.5688V21.2568H39.8182V30.7309Z" fill="white"/>
                    <path d="M55.9196 30.7309H54.6638L49.8942 23.4301V30.7309H48.6384V21.2568H49.8942L54.6768 28.5901V21.2568H55.9196V30.7309Z" fill="white"/>
                    <path d="M67.8046 29.0716L70.5114 21.2568H71.8779L68.3642 30.7309H67.258L63.7508 21.2568H65.1107L67.8046 29.0716Z" fill="white"/>
                    <path d="M85.0382 26.3517H80.9323V29.7093H85.7019V30.7309H79.683V21.2568H85.6368V22.2849H80.9323V25.3302H85.0382V26.3517Z" fill="white"/>
                    <path d="M96.5783 26.5079C95.5068 26.1999 94.726 25.8225 94.2358 25.3757C93.75 24.9246 93.507 24.3693 93.507 23.7099C93.507 22.9638 93.8042 22.3478 94.3985 21.862C94.9971 21.3718 95.7736 21.1267 96.7279 21.1267C97.3786 21.1267 97.9577 21.2525 98.4653 21.5041C98.9772 21.7557 99.3719 22.1027 99.6495 22.5452C99.9315 22.9877 100.072 23.4714 100.072 23.9962H98.8167C98.8167 23.4236 98.6345 22.9747 98.2701 22.6493C97.9057 22.3196 97.3916 22.1548 96.7279 22.1548C96.112 22.1548 95.6305 22.2914 95.2834 22.5647C94.9407 22.8337 94.7694 23.2089 94.7694 23.6904C94.7694 24.0765 94.932 24.404 95.2574 24.673C95.5871 24.9376 96.1445 25.1805 96.9297 25.4017C97.7192 25.623 98.3351 25.8681 98.7776 26.137C99.2244 26.4016 99.5541 26.7118 99.7667 27.0675C99.9836 27.4232 100.092 27.8418 100.092 28.3233C100.092 29.0911 99.7927 29.7071 99.1941 30.1713C98.5954 30.6311 97.7951 30.861 96.793 30.861C96.1423 30.861 95.535 30.7374 94.9711 30.4901C94.4072 30.2385 93.9712 29.8958 93.6632 29.462C93.3595 29.0282 93.2077 28.5359 93.2077 27.985H94.4635C94.4635 28.5576 94.6739 29.0109 95.0947 29.3449C95.5198 29.6746 96.0859 29.8394 96.793 29.8394C97.4524 29.8394 97.9577 29.705 98.3091 29.436C98.6605 29.1671 98.8362 28.8005 98.8362 28.3363C98.8362 27.8722 98.6735 27.5143 98.3482 27.2627C98.0228 27.0068 97.4329 26.7552 96.5783 26.5079Z" fill="white"/>
                    <path d="M114.775 22.2849H111.73V30.7309H110.487V22.2849H107.448V21.2568H114.775V22.2849Z" fill="white"/>
                    <path d="M129.816 26.2997C129.816 27.228 129.66 28.0392 129.347 28.7333C129.035 29.423 128.592 29.9501 128.02 30.3144C127.447 30.6788 126.779 30.861 126.016 30.861C125.27 30.861 124.608 30.6788 124.031 30.3144C123.454 29.9457 123.005 29.423 122.684 28.7463C122.368 28.0652 122.205 27.2779 122.196 26.3843V25.7011C122.196 24.7901 122.355 23.9854 122.671 23.287C122.988 22.5886 123.435 22.055 124.012 21.6863C124.593 21.3132 125.257 21.1267 126.003 21.1267C126.762 21.1267 127.43 21.3111 128.007 21.6798C128.588 22.0442 129.035 22.5756 129.347 23.274C129.66 23.9681 129.816 24.7771 129.816 25.7011V26.2997ZM128.573 25.688C128.573 24.5645 128.347 23.7034 127.896 23.1048C127.445 22.5018 126.814 22.2003 126.003 22.2003C125.213 22.2003 124.591 22.5018 124.135 23.1048C123.684 23.7034 123.452 24.5363 123.439 25.6034V26.2997C123.439 27.3885 123.667 28.2452 124.122 28.8699C124.582 29.4902 125.213 29.8004 126.016 29.8004C126.823 29.8004 127.447 29.5076 127.89 28.922C128.332 28.332 128.56 27.4883 128.573 26.3908V25.688Z" fill="white"/>
                    <path d="M141.694 26.8983H139.469V30.7309H138.213V21.2568H141.349C142.416 21.2568 143.236 21.4998 143.809 21.9856C144.386 22.4715 144.674 23.1785 144.674 24.1069C144.674 24.6968 144.514 25.2109 144.193 25.649C143.876 26.0871 143.434 26.4146 142.865 26.6315L145.091 30.6528V30.7309H143.75L141.694 26.8983ZM139.469 25.8767H141.388C142.009 25.8767 142.501 25.7162 142.865 25.3952C143.234 25.0742 143.419 24.6448 143.419 24.1069C143.419 23.5212 143.243 23.0723 142.891 22.7599C142.544 22.4476 142.041 22.2893 141.382 22.2849H139.469V25.8767Z" fill="white"/>
                    <path d="M162.796 30.7309V21.2568H165.471C166.295 21.2568 167.024 21.439 167.657 21.8034C168.29 22.1678 168.778 22.6862 169.121 23.3586C169.468 24.031 169.644 24.8031 169.648 25.675V26.2802C169.648 27.1738 169.475 27.9568 169.127 28.6292C168.785 29.3015 168.292 29.8177 167.65 30.1778C167.013 30.5378 166.269 30.7222 165.419 30.7309H162.796ZM164.046 22.2849V29.7093H165.36C166.323 29.7093 167.071 29.41 167.605 28.8113C168.143 28.2127 168.412 27.3603 168.412 26.2541V25.7011C168.412 24.6252 168.158 23.7902 167.65 23.1959C167.147 22.5973 166.431 22.2936 165.503 22.2849H164.046Z" fill="white"/>
                    <path d="M183.16 28.2583H179.191L178.299 30.7309H177.011L180.629 21.2568H181.722L185.346 30.7309H184.064L183.16 28.2583ZM179.568 27.2302H182.789L181.175 22.799L179.568 27.2302Z" fill="white"/>
                    <path d="M195.429 26.0134L197.901 21.2568H199.32L196.053 27.1976V30.7309H194.804V27.1976L191.537 21.2568H192.969L195.429 26.0134Z" fill="white"/>
                    <path d="M222.803 30.7309H216.596V29.8655L219.875 26.2216C220.361 25.6707 220.695 25.2239 220.877 24.8812C221.064 24.5342 221.157 24.1763 221.157 23.8075C221.157 23.313 221.007 22.9074 220.708 22.5908C220.409 22.2741 220.01 22.1158 219.511 22.1158C218.912 22.1158 218.446 22.2871 218.112 22.6298C217.782 22.9682 217.617 23.441 217.617 24.0483H216.414C216.414 23.1764 216.693 22.4715 217.253 21.9336C217.817 21.3957 218.57 21.1267 219.511 21.1267C220.391 21.1267 221.088 21.3588 221.6 21.8229C222.111 22.2828 222.367 22.8966 222.367 23.6644C222.367 24.5971 221.773 25.7076 220.584 26.9959L218.047 29.7483H222.803V30.7309Z" fill="white"/>
                    <path d="M236.549 26.6901C236.549 28.0999 236.309 29.1475 235.827 29.8329C235.346 30.5183 234.593 30.861 233.569 30.861C232.558 30.861 231.81 30.527 231.324 29.859C230.838 29.1866 230.587 28.1845 230.569 26.8528V25.2456C230.569 23.8531 230.81 22.8185 231.292 22.1418C231.773 21.4651 232.528 21.1267 233.556 21.1267C234.576 21.1267 235.326 21.4542 235.808 22.1092C236.289 22.7599 236.536 23.7663 236.549 25.1284V26.6901ZM235.346 25.0439C235.346 24.0244 235.202 23.2827 234.916 22.8185C234.63 22.35 234.176 22.1158 233.556 22.1158C232.94 22.1158 232.491 22.3478 232.209 22.812C231.927 23.2762 231.782 23.9897 231.773 24.9528V26.8788C231.773 27.9026 231.921 28.6595 232.216 29.1497C232.515 29.6356 232.966 29.8785 233.569 29.8785C234.163 29.8785 234.604 29.6486 234.89 29.1887C235.181 28.7289 235.333 28.0045 235.346 27.0154V25.0439Z" fill="white"/>
                    <path d="M250.829 30.7309H244.621V29.8655L247.901 26.2216C248.387 25.6707 248.721 25.2239 248.903 24.8812C249.089 24.5342 249.183 24.1763 249.183 23.8075C249.183 23.313 249.033 22.9074 248.734 22.5908C248.434 22.2741 248.035 22.1158 247.536 22.1158C246.938 22.1158 246.471 22.2871 246.137 22.6298C245.808 22.9682 245.643 23.441 245.643 24.0483H244.439C244.439 23.1764 244.719 22.4715 245.278 21.9336C245.842 21.3957 246.595 21.1267 247.536 21.1267C248.417 21.1267 249.113 21.3588 249.625 21.8229C250.137 22.2828 250.393 22.8966 250.393 23.6644C250.393 24.5971 249.799 25.7076 248.61 26.9959L246.072 29.7483H250.829V30.7309Z" fill="white"/>
                    <path d="M262.59 30.7309H261.38V22.7079L258.953 23.5993V22.5062L262.401 21.2113H262.59V30.7309Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M298.05 1.95016H1.95016V49.7291H298.05V1.95016ZM0 0V51.6793H300V0H0Z" fill="url(#paint0_linear_147:309)"/>
                    <defs>
                        <linearGradient id="paint0_linear_147:309" x1="300" y1="3.24381e-05" x2="3.28694e-07" y2="51.6793" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#1D4964"/>
                            <stop offset="0.54295" stopColor="#F4B848"/>
                            <stop offset="0.66323" stopColor="#D55325"/>
                            <stop offset="1" stopColor="#F7D0C0"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            <div ref={heroSection} className={styles.heroSection}>
                <div ref={fogRef} className={styles.heroBox}/>
                <div className={styles.heroBoxFgText}>
                    <svg width="596" height="26" viewBox="0 0 596 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.78369 25.6418H0.464661V0.472442H3.78369V25.6418Z" fill="white"/>
                        <path d="M46.5598 25.6418H43.2235L30.5524 6.24618V25.6418H27.2161V0.472442H30.5524L43.2581 19.9545V0.472442H46.5598V25.6418Z" fill="white"/>
                        <path d="M78.1342 21.2337L85.3255 0.472442H88.9557L79.6209 25.6418H76.6821L67.3646 0.472442H70.9775L78.1342 21.2337Z" fill="white"/>
                        <path d="M123.918 14.0079H113.01V22.9278H125.681V25.6418H109.691V0.472442H125.509V3.20373H113.01V11.2939H123.918V14.0079Z" fill="white"/>
                        <path d="M154.576 14.4228C151.73 13.6045 149.655 12.6019 148.353 11.4149C147.062 10.2163 146.417 8.74122 146.417 6.9895C146.417 5.0073 147.207 3.37083 148.785 2.0801C150.376 0.777839 152.439 0.126709 154.974 0.126709C156.703 0.126709 158.241 0.460917 159.59 1.12933C160.949 1.79775 161.998 2.7197 162.736 3.8952C163.485 5.07069 163.859 6.35566 163.859 7.75012H160.523C160.523 6.22889 160.039 5.03611 159.071 4.17178C158.103 3.29593 156.737 2.858 154.974 2.858C153.338 2.858 152.058 3.22102 151.136 3.94706C150.226 4.66157 149.771 5.65843 149.771 6.93764C149.771 7.96332 150.203 8.83341 151.067 9.54793C151.943 10.2509 153.424 10.8963 155.51 11.484C157.607 12.0718 159.244 12.7229 160.419 13.4374C161.606 14.1404 162.482 14.9644 163.047 15.9094C163.623 16.8544 163.911 17.9665 163.911 19.2457C163.911 21.2856 163.116 22.922 161.526 24.1551C159.935 25.3767 157.809 25.9875 155.147 25.9875C153.418 25.9875 151.805 25.6591 150.307 25.0022C148.808 24.3338 147.65 23.4233 146.832 22.2709C146.025 21.1184 145.622 19.8104 145.622 18.3468H148.958C148.958 19.868 149.517 21.0724 150.635 21.9597C151.764 22.8356 153.268 23.2735 155.147 23.2735C156.899 23.2735 158.241 22.9163 159.175 22.2017C160.108 21.4872 160.575 20.5134 160.575 19.2803C160.575 18.0472 160.143 17.0964 159.278 16.428C158.414 15.7481 156.847 15.0796 154.576 14.4228Z" fill="white"/>
                        <path d="M202.919 3.20373H194.829V25.6418H191.527V3.20373H183.454V0.472442H202.919V3.20373Z" fill="white"/>
                        <path d="M242.877 13.8696C242.877 16.3358 242.462 18.4909 241.633 20.3348C240.803 22.1672 239.627 23.5674 238.106 24.5354C236.585 25.5035 234.81 25.9875 232.782 25.9875C230.8 25.9875 229.042 25.5035 227.509 24.5354C225.977 23.5559 224.784 22.1672 223.931 20.3694C223.09 18.56 222.658 16.4683 222.635 14.0943V12.2792C222.635 9.85909 223.055 7.7213 223.897 5.86587C224.738 4.01044 225.925 2.59294 227.458 1.61336C229.002 0.622259 230.765 0.126709 232.747 0.126709C234.764 0.126709 236.539 0.616497 238.072 1.59607C239.616 2.56412 240.803 3.97587 241.633 5.8313C242.462 7.67521 242.877 9.82451 242.877 12.2792V13.8696ZM239.576 12.2446C239.576 9.25982 238.976 6.97222 237.778 5.38185C236.579 3.77995 234.902 2.979 232.747 2.979C230.65 2.979 228.996 3.77995 227.786 5.38185C226.587 6.97222 225.971 9.18491 225.936 12.0199V13.8696C225.936 16.7622 226.541 19.0383 227.751 20.6978C228.973 22.3458 230.65 23.1698 232.782 23.1698C234.925 23.1698 236.585 22.3919 237.76 20.8361C238.936 19.2688 239.541 17.0273 239.576 14.1116V12.2446Z" fill="white"/>
                        <path d="M274.434 15.46H268.522V25.6418H265.186V0.472442H273.518C276.353 0.472442 278.531 1.11781 280.053 2.40855C281.585 3.69928 282.352 5.57776 282.352 8.04399C282.352 9.61131 281.925 10.977 281.072 12.1409C280.231 13.3049 279.056 14.175 277.546 14.7512L283.458 25.4343V25.6418H279.897L274.434 15.46ZM268.522 12.746H273.622C275.27 12.746 276.578 12.3196 277.546 11.4667C278.526 10.6139 279.015 9.47302 279.015 8.04399C279.015 6.48819 278.549 5.29541 277.615 4.46565C276.693 3.6359 275.356 3.21525 273.605 3.20373H268.522V12.746Z" fill="white"/>
                        <path d="M330.496 25.6418V0.472442H337.6C339.79 0.472442 341.726 0.956468 343.409 1.92452C345.091 2.89257 346.388 4.26974 347.298 6.05603C348.22 7.84231 348.687 9.89366 348.698 12.2101V13.8177C348.698 16.1918 348.237 18.2719 347.315 20.0582C346.405 21.8445 345.097 23.2159 343.391 24.1724C341.697 25.1289 339.721 25.6187 337.462 25.6418H330.496ZM333.815 3.20373V22.9278H337.307C339.865 22.9278 341.853 22.1326 343.27 20.5422C344.699 18.9519 345.414 16.6873 345.414 13.7486V12.2792C345.414 9.42116 344.74 7.20271 343.391 5.62386C342.055 4.03349 340.153 3.22678 337.687 3.20373H333.815Z" fill="white"/>
                        <path d="M384.594 19.0729H374.05L371.681 25.6418H368.259L377.87 0.472442H380.774L390.403 25.6418H386.997L384.594 19.0729ZM375.052 16.3416H383.609L379.322 4.56937L375.052 16.3416Z" fill="white"/>
                        <path d="M417.189 13.109L423.758 0.472442H427.526L418.848 16.2551V25.6418H415.529V16.2551L406.851 0.472442H410.654L417.189 13.109Z" fill="white"/>
                        <path d="M489.914 25.6418H473.423V23.3427L482.135 13.6621C483.426 12.1985 484.313 11.0115 484.798 10.1011C485.293 9.17914 485.541 8.22838 485.541 7.2488C485.541 5.93502 485.143 4.85749 484.348 4.0162C483.553 3.17492 482.493 2.75428 481.167 2.75428C479.577 2.75428 478.338 3.20949 477.451 4.11992C476.575 5.01883 476.137 6.27499 476.137 7.88841H472.939C472.939 5.572 473.682 3.69928 475.169 2.27025C476.667 0.841223 478.667 0.126709 481.167 0.126709C483.507 0.126709 485.356 0.743266 486.716 1.97638C488.076 3.19797 488.756 4.82867 488.756 6.8685C488.756 9.34625 487.177 12.2965 484.02 15.7193L477.278 23.0315H489.914V25.6418Z" fill="white"/>
                        <path d="M526.433 14.9068C526.433 18.6522 525.793 21.4354 524.514 23.2562C523.235 25.0771 521.235 25.9875 518.515 25.9875C515.83 25.9875 513.842 25.1001 512.552 23.3254C511.261 21.5391 510.592 18.8769 510.546 15.339V11.0691C510.546 7.36981 511.186 4.62123 512.465 2.82342C513.744 1.02561 515.75 0.126709 518.481 0.126709C521.189 0.126709 523.183 0.996803 524.462 2.73699C525.741 4.46565 526.398 7.13932 526.433 10.758V14.9068ZM523.235 10.5333C523.235 7.82502 522.854 5.85435 522.094 4.62123C521.333 3.3766 520.129 2.75428 518.481 2.75428C516.844 2.75428 515.652 3.37083 514.903 4.60395C514.153 5.83706 513.767 7.73283 513.744 10.2913V15.4081C513.744 18.1279 514.136 20.1389 514.92 21.4411C515.715 22.7319 516.914 23.3772 518.515 23.3772C520.094 23.3772 521.264 22.7664 522.025 21.5449C522.797 20.3233 523.2 18.3987 523.235 15.7711V10.5333Z" fill="white"/>
                        <path d="M564.369 25.6418H547.877V23.3427L556.59 13.6621C557.88 12.1985 558.768 11.0115 559.252 10.1011C559.747 9.17914 559.995 8.22838 559.995 7.2488C559.995 5.93502 559.597 4.85749 558.802 4.0162C558.007 3.17492 556.947 2.75428 555.622 2.75428C554.031 2.75428 552.792 3.20949 551.905 4.11992C551.029 5.01883 550.591 6.27499 550.591 7.88841H547.393C547.393 5.572 548.136 3.69928 549.623 2.27025C551.121 0.841223 553.121 0.126709 555.622 0.126709C557.961 0.126709 559.811 0.743266 561.171 1.97638C562.53 3.19797 563.21 4.82867 563.21 6.8685C563.21 9.34625 561.632 12.2965 558.474 15.7193L551.732 23.0315H564.369V25.6418Z" fill="white"/>
                        <path d="M595.615 25.6418H592.399V4.32736L585.951 6.69563V3.79148L595.113 0.351435H595.615V25.6418Z" fill="white"/>
                    </svg>
                </div>

                <div ref={fogRef2} className={styles.heroBoxFg}>
                </div>

                <div className={styles.svgPath}>
                    <svg width="797" height="138" viewBox="0 0 797 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <clipPath id="svgPath">
                            <path fillRule="evenodd" clipRule="evenodd" d="M791.819 5.18093H5.18093V132.114H791.819V5.18093ZM0 0V137.295H797V0H0Z" fill="white"/>
                        </clipPath>
                    </svg>
                </div>
            </div>

            <div className={styles.heroDate}>
                <div className={styles.heroSubText}>
                    {subText}
                </div>
            </div>
        </div>

    )
}

export default Hero
