import React from 'react';
import Helmet from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";
import favicon from '../../images/favicon.ico'
import appleTouchIcon from '../../images/apple-touch-icon.png'
//import favicon16 from '../../images/favicon-16x16.png'
//import favicon32 from '../../images/favicon-32x32.png'

export default function Layout() {

  const data = useStaticQuery(graphql`
        query siteSettingsQuery {
            sanitySiteSettings {
                _key
                copyrightText
                description
                id
                privacyUrl
                termsUrl
                siteUrl
                title
                ogImage {
                    asset {
                        url
                    }
                }
            }
        }
    `)

  const settings = data.sanitySiteSettings

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{settings.title}</title>
        <meta name="description" content={settings.description}/>
        <link rel="icon" type="image/x-icon" href={favicon}/>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon}/>
        <link rel="icon" sizes="32x32" href={favicon}/>
        <link rel="icon" sizes="16x16" href={favicon}/>
        <meta name="msapplication-TileColor" content="#07082D"/>
        <meta property="og:title" content={settings.title}/>
        <meta property="og:description" content={settings.description}/>
        {(settings.siteUrl) && (
          <meta property="og:url" content={settings.siteUrl}/>
        )}
        {(settings.ogImage) && (
          <meta property="og:image" content={settings.ogImage.asset.url}/>
        )}
      </Helmet>
    </>
  );
}
