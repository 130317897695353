import React from 'react'
import {graphql, Link} from "gatsby";

import SiteHelmet from "../components/SiteHelmet/SiteHelmet";
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';
import Agenda from '../components/Agenda/Agenda';
import Speakers from '../components/Speakers/Speakers';
import Footer from '../components/Footer/Footer';
import Background from '../components/Background/Background';
import Chat from '../components/Chat/Chat';
import SignUpModal from "../components/SignUpModal/SignUpModal";

export const query = graphql`
    query eventQuery($eventId: String!) {
        sanityEvent(id: {eq: $eventId}) {
            id
            title
            eventDate
            topMenu {
                url
                title
                id
                dropdownItems {
                    dropdownItemTitle
                    dropdownItemUrl
                    hoverImage {
                        asset {
                            url
                        }
                    }
                    defaultImage {
                        asset {
                            url
                        }
                    }
                }
            }
            eventVideoSection {
                eventVideo
                videoImg {
                    asset {
                        url
                    }
                }
                notesText
                _rawVideoNotes
                videoDownloads {
                    ... on SanityVideoDownload {
                      _key
                      _type
                      title
                      description
                      type
                      asset {
                        url
                      }
                    }
                    ... on SanityVideoDownloadImage {
                      _key
                      _type
                      asset {
                        url
                      }
                      title
                      description
                    }
                }
                videoNotes {
                    timestamp
                    _rawContent
                }
            }
            agendaTitle
            currentAgendaItems {
                time
                title
                topic
                speakers {
                    id
                    firstName
                    lastName
                    professionalTitle
                }
            }
            speakersTitle
            currentSpeakerBios {
                id
                firstName
                lastName
                professionalTitle
                info
                headShotImage {
                    asset {
                        url
                    }
                }
            }
            footerAddressStreet
            footerAddressCityStateZip
            footerPhone
            footerSolutionCenter
            footerCopyright
            footerSocialLinkedInUrl
            footerSocialTwitterUrl
        }
    }
`

const PageTemplate = props => {

    const {data} = props

    const event = data.sanityEvent

    //console.log("EVENT:", event)

    const isBrowser = typeof window !== "undefined"

    const footerData = {
        footerAddressStreet: event.footerAddressStreet,
        footerAddressCityStateZip: event.footerAddressCityStateZip,
        footerPhone: event.footerPhone,
        footerSolutionCenter: event.footerSolutionCenter,
        footerCopyright: event.footerCopyright,
        footerSocialLinkedInUrl: event.footerSocialLinkedInUrl,
        footerSocialTwitterUrl: event.footerSocialTwitterUrl
    }

    return (
        <div className="main">
            <SiteHelmet />

            <Background />

            <Header menu={event.topMenu}/>

            <div className="body-wrap">

                <Hero heroText={event.title} subText={event.eventDate}/>

                <VideoPlayer video={event.eventVideoSection}/>

                <div id="agenda">
                    <Agenda title={event.agendaTitle} items={event.currentAgendaItems} />
                </div>

                <div id="speakers">
                    <Speakers title={event.speakersTitle} bios={event.currentSpeakerBios}/>
                </div>

                <Link
                    target="_blank" to="https://investors.premierinc.com/overview/default.aspx"
                    className="investor-mobile-text"
                    >
                Investor Relations Home
                </Link>
            </div>


            <Footer data={footerData}/>

            <SignUpModal/>
        </div>
    )
}

export default PageTemplate
