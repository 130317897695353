import React from 'react'
import AgendaItem from './AgendaItem'

function Agenda(props) {

    const {title, items} = props

    const createAgendaItem = (item,itemIndex) => {
        return (
            <AgendaItem key={`agenda-item-${itemIndex}`} item={item}/>
        )
    }

    const buildAgendaList = () => {
        if (items.length < 1) return null

        return items.map((item,itemIndex) => createAgendaItem(item,itemIndex))
    }

    const agendaList = buildAgendaList()

    const agendaWrapperClass = agendaList ? 'agenda' : 'agenda-disabled'

    return (
        <div className={agendaWrapperClass}>
            <div className="agenda-title">{title ? title : ""}</div>
            <div className="agenda-items">

                {agendaList ? agendaList : ""}

            </div>
        </div>
    )
}

export default Agenda
