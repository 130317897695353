import React, {useState, useRef, useEffect} from 'react';
import * as styles from './SignUpModal.module.scss'
import PremierWhiteLogo from '../../svg/premier-white.svg'
import InvestorDayBanner from '../../svg/investor-day-modal.svg'
import { useForm } from "react-hook-form";
import { useCookies } from 'react-cookie'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB-D6DYIy3VSOIgJni0yHVajrv9QIQI-YQ",
    authDomain: "premier-investor-day.firebaseapp.com",
    projectId: "premier-investor-day",
    storageBucket: "premier-investor-day.appspot.com",
    messagingSenderId: "661880680039",
    appId: "1:661880680039:web:940bd80dc8175767d1eec2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export default function SignUpModal( ) {
    const [cookies, setCookie, removeCookie] = useCookies(['registered']);
    const [modalClass, setModalClass] = useState(null)
    const isBrowser = typeof window !== "undefined"

    const useFirstRender = () => {
        const ref = useRef(true);
        const firstRender = ref.current;
        ref.current = false;
        return firstRender;
    };

    function addContact(db, contact) {
        const contacts = collection(db, 'contacts');

        try {
            const docRef = addDoc(contacts, contact);
        } catch (e) {
            console.error("Error adding document: ", e);
        }

        return true;
    }

    const { register, handleSubmit, formState: { errors } } = useForm()

    // check cookies, and show modal if we have not registered yet

    useEffect(() => {
        if(isBrowser) {
            console.log('cookies',cookies.registered)
            if(!cookies.registered) {
                console.log('show modal')
                setModalClass(styles.modalOverlay)
            } else {
                console.log('hide modal')
                setModalClass(styles.modalOverlayClosed)
            }
        }
    }, [cookies])


    const onSubmit = data => {
        //console.log('data', data)

        addContact(db, {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            organization: data.organization
        })

        setCookie('registered', 1, { path: '/' });
    }

    return (
        <div className={modalClass}>
            <div className={styles.modalBox}>
                <div className={styles.premierLogoContainer}>
                    <PremierWhiteLogo/>
                </div>

                <div className={styles.investorDayBannerContainer}>
                    <InvestorDayBanner/>
                </div>

                <div className={styles.modalContent}>
                    <p>
                        Please fill out the form below to access the Investor Day 2021 live stream.
                    </p>
                </div>
                <div className={styles.formContainer}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.controlContainer}>
                            <input id="firstName"
                                   className={styles.formControl}
                                   {...register('firstName', {required: true})}
                                   placeholder="FIRST NAME"
                                   type="text"/>
                            {errors.firstName && <span className={styles.error}>This field is required.</span>}
                        </div>

                        <div className={styles.controlContainer}>
                            <input id="lastName"
                                   className={styles.formControl}
                                   {...register('lastName', {required: true})}
                                   placeholder="LAST NAME"
                                   type="text"/>
                            {errors.firstName && <span className={styles.error}>This field is required.</span>}
                        </div>

                        <div className={styles.controlContainer}>
                            <input id="emailAddress"
                                   className={styles.formControl}
                                   {...register('email', {required: true, pattern: /^\S+@\S+$/i})}
                                   placeholder="EMAIL"
                                   type="text"/>
                            {errors.email && <span className={styles.error}>A valid email is required.</span>}
                        </div>

                        <div className={styles.controlContainer}>
                            <input id="organization"
                                   className={styles.formControl}
                                   {...register('organization', {required: true})}
                                   placeholder="ORGANIZATION"
                                   type="text"/>
                            {errors.organization && <span className={styles.error}>This field is required.</span>}
                        </div>

                        <button className={styles.modalButton}>Enter</button>
                    </form>
                </div>

            </div>
        </div>
    );
}
