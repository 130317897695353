import React, { useState } from 'react'
import DropdownArrow from '../../images/dropdown-arrow.svg'
import { Link } from "gatsby"

function Dropdown(props) {

    const {item} = props

    const [toggled, setToggled] = useState(false)

    const handleKeyDown = (ev) => {
        if (ev.keyCode === 13) setToggled(!toggled)
    }

    const handleClick = () => {
        setToggled(!toggled)
    }

    const menuItemGen = item.dropdownItems.map((dropdownItem,index)=>{
        return (
            <Link to={`${dropdownItem.dropdownItemUrl}`} target="_blank" className="dropdown-item" key={`img-${index}`}>
                <img className="dropdown-item-default" src={dropdownItem.defaultImage.asset.url} alt={`${dropdownItem.title}`}/>
                <img className="dropdown-item-hover" src={dropdownItem.hoverImage.asset.url} alt={`${dropdownItem.title}`}/>
            </Link>
        )
    })

    return (
        <div className="menu-item">
            <div className={`dropdown-our-brands ${toggled ? "dropdown-show" : ""}`}>
                <div className="dropdown-text"
                     role="button"
                     tabIndex="0"
                     onClick={handleClick}
                     onKeyDown={handleKeyDown}>
                    {item.title} <DropdownArrow />
                </div>
                <div className="dropdown-items">
                    {menuItemGen}
                </div>
            </div>
        </div>
    )
}


export default Dropdown
