import React from 'react'
import LogoPremier from '../../images/premier-logo.svg'
import Menu from '../Menu/Menu'
import { Link } from "gatsby"

function Header(props) {
    
    const {menu} = props

    return (
        <div className="header">
            <Link target="_blank" to={"https://premierinc.com"} className="logo-premier">
                <LogoPremier />
            </Link>
            
            <Menu menu={menu}/>
        </div>
    )
}

export default Header