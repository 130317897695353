import React from 'react'
import Dropdown from './Dropdown'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"
function Menu(props) {

    const {menu} = props

    const linkGen = (item,index) => {
        //console.log('item', item)
        return (
            <div className='menu-item-container' key={`link-${index}`}>
                {(item.url) && (
                    <>
                        {(item.url.indexOf('#') >= 0) && (
                            <AnchorLink target="_blank" className="menu-item" to={`/${item.url}`}>
                                {item.title}
                            </AnchorLink>
                        )}
                        {(item.url.indexOf('#') < 0) && (
                            <Link className="menu-item"
                               to={item.url}
                               target="_blank"
                            >
                                {item.title}
                            </Link>
                        )}
                    </>
                )}
            </div>
        )
    }

    const dropdownGen = (item,index) => {
        return (
            <Dropdown item={item} key={`dropdown-${index}`}/>
        )
    }

    const itemListGen = menu.map((item,itemIndex)=>{
        return item?.dropdownItems.length > 0 ?  dropdownGen(item,itemIndex) : linkGen(item,itemIndex)
    })

    return (
        <div className="menu-wrap">
            {itemListGen}
        </div>
    )
}

export default Menu
