import React from 'react'
import PortableText from '@sanity/block-content-to-react'
import VideoEmbed from './VideoEmbed'
import {Link} from 'gatsby'
import VideoDownload from './VideoDownload'
import { useStaticQuery, graphql } from 'gatsby'
import VideoGallery from './VideoGallery'

const serializers = {
    marks: {
        internalLink: ({ mark, children }) => {
            const { slug = {} } = mark
            const href = `/${slug.current}`
            return <Link href={href} target="_blank">{children}</Link>
        },
        link: ({ mark, children }) => {
            const { blank, href } = mark
            return blank ?
                <Link to={href} target="_blank" rel="noopener">{children}</Link>
                : <Link to={href} target="_blank">{children}</Link>
        }
    }
}

function VideoPlayer(props) {

    const vdata = useStaticQuery(graphql`
    query VideosQuery {
        dataJson(entries: {elemMatch: {media_content: {elemMatch: {medium: {eq: "video"}}}}}) {
            entries {
                media_content {
                    url
                    medium
                    height
                    width
                }
            }
        }
    }`)

    const mc = vdata.dataJson.entries
    const video_and_img_urls = mc.map(v => v.media_content
        .filter(m => m.medium === "video" || m.width === "640")
        .reduce((a,b) => {
            a[b.medium] = b.url
            return a
        }, {})
    )
    //console.log("VIDEO DATA", mc)
    console.log("VIDEO DATA", video_and_img_urls)




    const { video } = props

    const notes = video._rawVideoNotes ? video._rawVideoNotes : []

    const vidNotes = notes.filter(note => note._type === 'videoNote')

    const vidDownloads = video.videoDownloads ? video.videoDownloads : []

    function noteGen(note, index) {

        const blocks = note.content

        return (
            <div key={`note-${index}`} className="video-player-note">
                <div className="video-player-note-timestamp">{note.timestamp}</div>
                <div className="video-player-note-text">
                    <PortableText
                        blocks={blocks}
                        serializers={serializers}
                    />
                </div>
            </div>
        )
    }

    function downloadGen(download, index) {
        return (
            <VideoDownload key={`download-${index}`} item={download} />
        )
    }

    const vDL = vidDownloads.map(downloadGen)

    const downloadSection = vDL.length > 0 ? <div className="video-player-downloads">{vDL}</div> : null

    return (
        <div className="video-player">
            {/* <VideoEmbed /> */}
            <VideoGallery toggled={true} handleClick={()=>console.log("CLICK")} sessions={video_and_img_urls}/>

            <div className="video-player-notes">
                <div className="video-player-notes-title">{video.notesText}</div>

                {vidNotes.map(noteGen)}
                {downloadSection}
            </div>
        </div>
    )
}

export default VideoPlayer
